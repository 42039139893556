import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling


// variables used in scene
var gui, canvas, scene, renderer, textureLoader, camera, controls, clock, cube, model, uniforms1, composer;
var sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
var ang = 0;

var cubes = [];

function init2D() {

    // add UI furniture
    gsap.to("#headerlogo", {
        opacity: 1,
        duration: 0.5,
        delay: 3.7,
        ease: "quad.out",

        onBegin: function() {
            var element = document.getElementById("introsvg");
            element.parentNode.removeChild(element);
        },
        onComplete: function() {
            // init the 3D scene
            init3D()
        }
    });

    // animate icons in
    gsap.to("#button_mail", {
        autoAlpha: 0.4,
        duration: 0.3,
        delay: 7.0,
        ease: "quad.out"
    });
    gsap.to("#button_tel", {
        autoAlpha: 0.4,
        duration: 0.3,
        delay: 7.1,
        ease: "quad.out",
    });
    gsap.to("#button_cv", {
        autoAlpha: 0.4,
        duration: 0.3,
        delay: 7.2,
        ease: "quad.out",
    });
    gsap.to("#button_li", {
        autoAlpha: 0.4,
        duration: 0.3,
        delay: 7.3,
        ease: "quad.out",
    });
    gsap.to("#button_plus", {
        autoAlpha: 0.4,
        duration: 0.3,
        delay: 8.0,
        ease: "quad.out",
    });
    gsap.to(".webgl", {
        duration: 3,
        delay: 4,
        autoAlpha: 1,
        ease: "quad.out",
    });

    // animate welcome in
    gsap.to("#welcome_title", {
        opacity: 1,
        duration: 0.5,
        delay: 5.2,
        ease: "quad.out",
    });
    gsap.to("#welcome_sub", {
        opacity: 1,
        duration: 0.5,
        delay: 5.6,
        ease: "quad.out",
    });
    gsap.to("#welcome_p1", {
        opacity: 1,
        duration: 0.6,
        delay: 6.0,
        ease: "quad.out",
    });
    gsap.to("#welcome_p2", {
        opacity: 1,
        duration: 0.6,
        delay: 6.6,
        ease: "quad.out",
    });

    // add icon rollover logic
    $('.button_mail_style').mouseenter(function() {
        gsap.to('.button_mail_style', { duration: 0.3, opacity: 0.9, transformOrigin: 'center center' })

    })
    $('.button_mail_style').mouseleave(function() {
        gsap.to('.button_mail_style', { duration: 0.5, opacity: 0.4 })
    })
    $('.button_tel_style').mouseenter(function() {
        gsap.to('.button_tel_style', { duration: 0.3, opacity: 0.9, transformOrigin: 'center center' })

    })
    $('.button_tel_style').mouseleave(function() {
        gsap.to('.button_tel_style', { duration: 0.5, opacity: 0.4 })
    })
    $('.button_cv_style').mouseenter(function() {
        gsap.to('.button_cv_style', { duration: 0.3, opacity: 0.9, transformOrigin: '10px 10px' })

    })
    $('.button_cv_style').mouseleave(function() {
        gsap.to('.button_cv_style', { duration: 0.5, opacity: 0.4 })
    })
    $('.button_li_style').mouseenter(function() {
        gsap.to('.button_li_style', { duration: 0.3, opacity: 0.9, transformOrigin: '10px 10px' })

    })
    $('.button_li_style').mouseleave(function() {
        gsap.to('.button_li_style', { duration: 0.5, opacity: 0.4 })
    })

    $('.button_plus_style').mouseenter(function() {
        gsap.to('.button_plus_style', { duration: 0.3, opacity: 1.0, rotation: 180 })

    })
    $('.button_plus_style').mouseleave(function() {
        gsap.to('.button_plus_style', { duration: 0.5, opacity: 0.4, rotation: 0 })
    })

    // tooltips
    tippy('#button_li', {
        content: 'LinkedIn',
        arrow: false,
        offset: [0, 8]
    });
    tippy('#button_cv', {
        content: 'Download CV',
        arrow: false,
        offset: [-10, 12]
    });
    tippy('#button_tel', {
        content: 'Tel: +61 415 974 089',
        arrow: false,
        offset: [0, 13]
    });
    tippy('#button_mail', {
        content: 'Email me',
        arrow: false
    });
    tippy('#button_plus', {
        content: 'Experiments',
        arrow: false
    });
}


function init3D() {
    // gui = new dat.GUI()
    canvas = document.querySelector('canvas.webgl');
    scene = new THREE.Scene();
    textureLoader = new THREE.TextureLoader();

    // const axesHelper = new THREE.AxesHelper(5);
    // scene.add(axesHelper);

    // lighting
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7);
    directionalLight.position.set(1, 8, 0).normalize();
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 1024; // default
    directionalLight.shadow.mapSize.height = 1024; // default
    scene.add(directionalLight);

    // cam
    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
    camera.position.x = 0;
    camera.position.y = 7; //1.9
    camera.position.z = 0; //5
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    scene.add(camera);

    // renderer
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        alpha: true
    })
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    //renderer.setClearColor(new THREE.Color(0x414141));
    renderer.setClearColor(new THREE.Color(0x242020));

    composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    // const pass = new BokehPass(scene, camera, {
    //     focus: 0.3,
    //     aperture: 35,
    //     maxblur: 0.005
    // })
    // pass.renderToScreen = true;
    // pass.clearColor = new THREE.Color(0, 0, 0);
    // pass.clearAlpha = 0;
    // composer.addPass(pass);

    // used in tick
    clock = new THREE.Clock();

    // resize
    window.addEventListener('resize', () => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();

        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        // renderer.setClearColor(new THREE.Color(0x414141));
    });

    const matcapTexture = new THREE.TextureLoader().load('/textures/matcaps/6.png')
    for (let rows = 0; rows < 30; rows++) {
        var row = [];
        for (let index = 0; index < 40; index++) {
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshMatcapMaterial({ color: 0x323232, matcap: matcapTexture });
            const cube = new THREE.Mesh(geometry, material);
            cube.position.x = -14 + index;
            cube.position.z = -14.5 + rows;
            cube.castShadow = false;
            cube.receiveShadow = false;
            row.push(cube);
            scene.add(cube);
        }
        cubes.push(row);
    }


    // begin anim
    tick()
}

let then = 0;
const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    ang += 0.01;

    // Update controls
    // controls.update();

    // rows
    for (let rows = 0; rows < cubes.length; rows++) {
        var offset = (rows * 10) / cubes.length;
        for (let index = 0; index < cubes[rows].length; index++) {
            const element = cubes[rows][index];
            //element.scale.y = 2 + Math.sin(ang + (index / 2) + offset)
            element.position.y = 0.1 + Math.sin(ang + (index / 2) + offset)
        }
    }

    camera.lookAt(new THREE.Vector3(Math.sin(ang / 20) * 5.5, 0, 0));
    camera.position.y = 5 + Math.sin(ang / 3);


    // Render
    // renderer.render(scene, camera);
    var now = clock.getElapsedTime();
    now *= 0.001; // convert to seconds
    const deltaTime = now - then;
    then = now;
    composer.render(deltaTime);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}


init2D();